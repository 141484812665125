import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Barliner Workout',
      page: 'home'
    }
  },
  {
    path: '/classes',
    name: 'Classes',
    component: () => import('../views/Classes.vue'),
    meta: {
      title: 'Pricing — Barliner Workout',
      page: 'classes'
    }
  },
  {
    path: '/inclusivity',
    name: 'Inclusive',
    component: () => import('../views/Inclusive.vue'),
    meta: {
      title: 'Inclusivity — Barliner Workout',
      page: 'inclusivity'
    }
  },
  {
    path: '/livestream',
    name: 'LivestreamClasses',
    component: () => import('../views/LivestreamClasses.vue'),
    meta: {
      title: 'Livestream Training — Barliner Workout',
      page: 'livestream'
    }
  },
  {
    path: '/group-training',
    name: 'GroupTraining',
    component: () => import('../views/GroupTraining.vue'),
    meta: {
      title: 'Gym Training — Barliner Workout',
      page: 'groupClasses'
    }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/Personal.vue'),
    meta: {
      title: 'Personal Training — Barliner Workout',
      page: 'personal'
    }
  },
  {
    path: '/equipment',
    name: 'Equipment',
    component: () => import('../views/Equipment.vue'),
    meta: {
      title: 'Equipment — Barliner Workout',
      page: 'equipment'
    }
  },
  {
    path: '/personal-coaching',
    name: 'PersonalCoaching',
    component: () => import('../views/PersonalCoaching.vue'),
    meta: {
      title: 'Personal Training — Barliner Workout',
      page: 'personal'
    }
  },
  {
    path: '/imprints',
    // redirect: '/',
    name: 'Imprints',
    component: () => import('../views/Imprints.vue'),
    meta: {
      title: 'Imprints — Barliner Workout',
      page: 'Imprints'
    }
  },
  {
    path: '/privacy',
    // redirect: '/',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),
    meta: {
      title: 'Privacy — Barliner Workout',
      page: 'privacy'
    }
  },
  {
    path: '/agb',
    // redirect: '/',
    name: 'AGB',
    component: () => import('../views/AGB.vue'),
    meta: {
      title: 'AGB — Barliner Workout',
      page: 'AGB'
    }
  },
  {
    path: '*',
    redirect: '/'
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop, // - 85,
        behavior: 'smooth'
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Barliner Workout'
  next()
})

export default router
