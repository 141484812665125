<template>
  <div class="home">
    <b-container fluid class="p-0">
      <div class="header-bg"></div>
      <div class="w-100">
        <b-container class="bg-secondary">
          <div class="hide-desktop">
            <div class="container-header-mobile hide-desktop">
              <div class="div-header-title-mobile">
                <h1 class="display-bw-2 header-title">
                  {{ $t("home.headerTitle") }}
                </h1>
              </div>

              <div class="div-header-cards-mobile">
                <div class="div-cards">
                  <b-card border-variant="light" class="shadow card-header-mobile">
                    <div class="d-flex">
                      <h3 class="h3-bw h3-bw-custom">
                        {{ $t("home.header.card1.title") }}
                      </h3>
                      <p class="p-bw-bold highlighter green ml-auto">
                        {{ $t("home.header.card1.subtitle") }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="p-bw mt-3">
                        {{ $t("home.header.card1.content") }}
                      </p>
                      <div class="btn-grid-mobile">
                        <router-link :to="{ name: 'GroupTraining' }" class="mb-3">
                          <img src="../assets/icons/svg/mobile-front-arrow.svg">
                        </router-link>
                      </div>
                    </div>
                  </b-card>

                  <b-card border-variant="light" class="shadow card-header-mobile">
                    <div class="d-flex">
                      <h3 class="h3-bw h3-bw-custom">
                        {{ $t("home.header.card2.title") }}
                      </h3>
                      <p class="p-bw-bold highlighter lightblue ml-auto">
                        {{ $t("home.header.card2.subtitle") }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="p-bw mt-3">
                        {{ $t("home.header.card2.content") }}
                      </p>
                      <div class="btn-grid-mobile">
                        <router-link :to="{ name: 'Personal' }" class="mb-3">
                          <img src="../assets/icons/svg/mobile-front-arrow.svg">
                        </router-link>
                      </div>
                    </div>
                  </b-card>

                  <b-card border-variant="light" class="shadow card-header-mobile">
                    <div class="d-flex">
                      <h3 class="h3-bw h3-bw-custom">
                        {{ $t("home.header.card3.title") }}
                      </h3>
                      <p class="p-bw-bold highlighter bg-error ml-auto">
                        {{ $t("home.header.card3.subtitle") }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="p-bw mt-3">
                        {{ $t("home.header.card3.content") }}
                      </p>
                      <div class="btn-grid-mobile">
                        <router-link :to="{ name: 'Equipment' }" class="mb-3">
                          <img src="../assets/icons/svg/mobile-front-arrow.svg">
                        </router-link>
                      </div>
                    </div>
                  </b-card>
                </div>
              </div>
            </div>
          </div>

          <div class="hide-mobile">
            <div class="container-header">
              <div class="div-header-title">
                <b-navbar-brand to="/" exact active-class="active">
                  <img
                    class="logo-header"
                    src="../assets/imgs/logo_barliner.svg"
                    alt="Barliner Workout"
                  >
                </b-navbar-brand>
                <h1 class="display-bw-2 header-title">
                  {{ $t("home.headerTitle") }}
                </h1>
              </div>

              <div class="div-header-cards hide-mobile">
                <div class="div-cards">
                  <b-card-group deck class="mx-0">
                    <b-card border-variant="light" class="shadow card-header">
                      <div class="d-flex flex-column" style="height: 270px;">
                        <p class="p-bw-bold highlighter green mx-auto">
                          {{ $t("home.header.card1.subtitle") }}
                        </p>
                        <h3 class="h3-bw h3-bw-custom">
                          {{ $t("home.header.card1.title") }}
                        </h3>
                        <p class="p-bw my-3">
                          {{ $t("home.header.card1.content") }}
                        </p>
                        <b-button variant="dark" class="w-100 mt-auto"
                          :to="{ name: 'GroupTraining' }"
                        >
                          {{ $t("home.header.card1.buttonLabel") }}
                        </b-button>
                      </div>
                    </b-card>

                    <b-card border-variant="light" class="shadow card-header">
                      <div class="d-flex flex-column" style="height: 270px;">
                        <p class="p-bw-bold highlighter lightblue mx-auto">
                          {{ $t("home.header.card2.subtitle") }}
                        </p>
                        <h3 class="h3-bw h3-bw-custom">
                          {{ $t("home.header.card2.title") }}
                        </h3>
                        <p class="p-bw my-3">
                          {{ $t("home.header.card2.content") }}
                        </p>
                        <b-button variant="dark" class="w-100 mt-auto"
                          :to="{ name: 'Personal' }"
                        >
                          {{ $t("home.header.card2.buttonLabel") }}
                        </b-button>
                      </div>
                    </b-card>

                    <b-card border-variant="light" class="shadow card-header">
                      <div class="d-flex flex-column" style="height: 270px;">
                        <p class="p-bw-bold highlighter bg-error mx-auto">
                          {{ $t("home.header.card3.subtitle") }}
                        </p>
                        <h3 class="h3-bw h3-bw-custom">
                          {{ $t("home.header.card3.title") }}
                        </h3>
                        <p class="p-bw my-3">
                          {{ $t("home.header.card3.content") }}
                        </p>
                        <b-button variant="dark" class="w-100 mt-auto" to="/equipment">
                          {{ $t("home.header.card3.buttonLabel") }}
                        </b-button>
                      </div>
                    </b-card>
                  </b-card-group>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </b-container>

    <!-- false container to add spacing -->
    <div class="py-bw-5 hide-mobile">
    </div>

    <b-container class="py-bw-5 text-md-left section--promise">
      <div class="section-promise">
        <div class="img-landing-group-a" alt="Barliner Workout"></div>
        <div class="promise-text">
            <p class="p-bw-bold mb-2">
              {{ $t('home.section1.subtitle') }}
            </p>
            <h2 class="h2-bw mb-4">
              {{ $t('home.section1.title') }}
            </h2>
            <p class="p-bw">
              {{ $t('home.section1.content') }}
            </p>
        </div>
      </div>
    </b-container>

    <!-- <b-container id="virtualWorkouts"
      class="py-bw-5 hide-mobile section--virtual-workouts"
    >
      <b-row class="mb-5">
        <b-col cols="12">
          <p class="p-bw-bold mx-auto mb-2">
            {{ $t("home.section2.subtitle") }}
          </p>
          <h2 class="h2-title">
            {{ $t("home.section2.title") }}
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="pr-md-5">
          <img class="img-classes-1" src="../assets/imgs/landing_classes_livestream1.png" alt="livestream group classes">
          <b-card class="shadow-sm" border-variant="light px-5 py-4">
            <div class="d-flex flex-column"  style="height:260px;">
              <p class="p-bw-bold highlighter lightblue mx-auto">
                {{ $t('home.section2.card1.subtitle') }}
              </p>
              <h3 class="h3-bw">
                {{ $t('home.section2.card1.title') }}
              </h3>
              <p class="p-bw my-3">
                {{ $t('home.section2.card1.content') }}
              </p>
              <b-button
                variant="dark"
                class="w-100 mt-auto"
                :to="{ name: 'LivestreamClasses', hash: '#virtualWorkoutsClasses' }"
              >
                {{ $t('home.section2.card1.buttonLabel') }}
              </b-button>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" md="6" class="pl-md-5 mt-sm-6">
          <picture>
            <source
              media="(max-width: 768px)"
              srcset="../assets/imgs/landing_classes_in_person_480.jpg"
            >
            <source
              media="(min-width: 800px)"
              srcset="../assets/imgs/landing_classes_in_person_800.png"
            >
            <img src="../assets/imgs/landing_classes_in_person_800.png"
              class="img-classes-1 img"
              alt="livestream group classes"
              style="filter: grayscale(100%);"
            >
          </picture>
          <b-card class="shadow-sm" border-variant="light px-5 py-4">
            <div class="d-flex flex-column"  style="height:260px;">
              <p class="p-bw-bold highlighter lightblue mx-auto">
                {{ $t('home.section2.card2.subtitle') }}
              </p>
              <h3 class="h3-bw">
                {{ $t('home.section2.card2.title') }}
              </h3>
              <p class="p-bw my-3">
                {{ $t('home.section2.card2.content') }}
              </p>
              <b-button
                variant="outline-primary"
                class="w-100 mt-auto"
                to="/workouts"
                disabled
              >
                {{ $t('home.section2.card2.buttonLabel') }}
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container> -->

    <b-container fluid class="bg-accent section--calender">
      <fitogram></fitogram>
    </b-container>

    <b-container class="py-bw-5 text-md-left section--calisthenics">
      <div class="section-calisthenics">
        <div class="img-landing-group-b" alt="Barliner Workout"></div>
        <div class="calisthenics-text">
          <p class="p-bw-bold mb-2">
            {{ $t('home.section4.subtitle') }}
          </p>
          <h3 class="h2-bw mb-4">
            {{ $t('home.section4.title') }}
          </h3>
          <p class="p-bw">
            {{ $t('home.section4.content') }}
          </p>
        </div>
      </div>
    </b-container>

    <b-container id="inclusionPolicy" fluid="sm">
      <div class="grid-inclusion py-bw-5">
        <div class="grid-inclusion__img"></div>
        <div class="grid-inclusion__card">
          <p class="p-bw-bold mb-2">
            {{ $t('home.section5.subtitle') }}
          </p>
          <h3 class="h3-bw">
            {{ $t("home.section5.title") }}
          </h3>
          <p class="p-bw grid-inclusion__card__content">
            {{ $t("home.section5.content.para1") }} <br><br>
            {{ $t("home.section5.content.para2") }} <br><br>
            {{ $t("home.section5.content.para3") }}
          </p>
          <b-button
            variant="dark"
            class="w-100 grid-inclusion__card__button"
            :to="{ name: 'Inclusive' }"
          >
            {{ $t("home.section5.buttonLabel") }}
          </b-button>
        </div>
      </div>
    </b-container>

    <b-container fluid class="py-bw-5 trainers-container section--trainers" id="trainers">
      <b-container>
        <div class="trainers-card">
          <p class="p-bw-bold mb-2">
            {{ $t("home.section6.subtitle") }}
          </p>
          <h2 class="h2-bw">
            {{ $t("home.section6.title") }}
          </h2>
          <p class="p-bw trainers-card__content">
            {{ $t("home.section6.content") }}
          </p>
        </div>
      </b-container>
      <b-row class="trainers-swiper-container">
        <b-col class="px-0">
          <swiper ref="trainersSwiper"
            :options="swiperOptionTrainers"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
          >
            <swiper-slide :key="trainer.id" v-for="trainer in trainers">
              <div class="card-trainer">
                <img
                  :src="require(`@/assets/imgs/trainers/${trainer.imgSrc}`)"
                  :alt="trainer.name"
                >
              </div>
            </swiper-slide>
          </swiper>
        </b-col>
      </b-row>
      <div class="trainers-actions">
        <b-button
          variant="dark"
          class="trainers-actions__button1"
          :to="{ name: 'GroupTraining' }"
        >
          {{ $t("home.btnTrainWithUs") }}
        </b-button>
      </div>
    </b-container>

    <b-container fluid class="bg-accent py-bw-5 hide-mobile">
      <b-row class="">
        <b-col class="">
          <swiper ref="quotesSwiper"
            :options="swiperOptionQuotes"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
          >
            <swiper-slide :key="quote.id" v-for="quote in quotes">
              <b-container>
                <div class="mx-auto px-5">
                  <h3 class="h3-bw roboto-quote bg-quote mb-5 pt-5">
                    {{ quote.message }}
                  </h3>
                  <p class="p-bw-bold color-error">
                    — {{ quote.name }}, Barliner since {{ quote.joiningYear }}
                  </p>
                </div>
              </b-container>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev" @click="prevQuote()"></div>
            <div class="swiper-button-next" slot="button-next" @click="nextQuote()"></div>
          </swiper>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <div class="section-divider"></div>
    </b-container>

    <Contact></Contact>
  </div>
</template>

<script>
// @ is an alias to /src
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'Home',
  components: {
    Contact: () => import('@/components/Contact'),
    Fitogram: () => import('@/components/Fitogram')
  },
  data () {
    return {
      trainers: [
        {
          id: 1,
          name: 'Denis',
          imgSrc: 'trainer-snip1.jpg'
        },
        {
          id: 2,
          name: 'Denis',
          imgSrc: 'trainer-snip2.jpg'
        },
        {
          id: 3,
          name: 'Denis',
          imgSrc: 'trainer-snip3.jpg'
        },
        {
          id: 4,
          name: 'Denis',
          imgSrc: 'trainer-snip4.jpg'
        },
        {
          id: 5,
          name: 'Denis',
          imgSrc: 'trainer-snip5.jpg'
        },
        {
          id: 6,
          name: 'Denis',
          imgSrc: 'trainer-snip2.jpg'
        },
        {
          id: 7,
          name: 'Denis',
          imgSrc: 'trainer-snip4.jpg'
        },
        {
          id: 8,
          name: 'Denis',
          imgSrc: 'trainer-snip1.jpg'
        },
        {
          id: 9,
          name: 'Denis',
          imgSrc: 'trainer-snip5.jpg'
        },
        {
          id: 10,
          name: 'Denis',
          imgSrc: 'trainer-snip3.jpg'
        }
      ],
      swiperOptionTrainers: {
        slidesPerView: 5,
        spaceBetween: 0,
        freeMode: true,
        grabCursor: true,
        loop: true,
        initialSlide: 0,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true
        },
        speed: 800,
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 4,
            spaceBetween: 0
          }
        }
      },
      quotes: [
        {
          id: 5,
          name: 'Nick',
          message: 'Denis ist seit über einem Jahr mein Trainer. Meistens trainiere ich viermal pro Woche. Er bietet ein abwechslungsreiches und strukturiertes Training an. Es ist genau auf mein Level ausgerichtet und wird stetig an meine Fortschritte angepasst. Denis legt viel Wert auf das richtige Warm Up und die korrekte Ausführung von Bewegungen. Wir trainieren zuerst auf Bewegungen hin und im zweiten Schritt auf Wiederholungen und Gewichte. Das Besondere an Denis ist, ist dass er alle Übungen selbst perfekt beherrscht und immer motiviert ist. Das Training macht sehr viel Spaß!',
          joiningYear: '2019'
        },
        {
          id: 11,
          name: 'Mon',
          message: 'I was immediately struck by how lovely and supportive everyone in this community is. When I came I couldn’t even do a single pull up, but with the help and experience of Denis I quickly overcame a lot of my difficulties and currently I am achieving movements that months ago sounded impossible.\n\n The care and knowledge that Denis displays in every single training session is unparalleled and I feel really grateful for being part of this family.',
          joiningYear: '2021'
        },
        {
          id: 12,
          name: 'Dung',
          message: 'I started doing calisthenics in October 2019 at Barliner Calisthenics and I have to say that it\'s one of the best decisions I have ever made. Back then I was a complete beginner and couldn\'t do much of the calisthenics basics exercises but after a year the progress I\'ve made blew my mind, and that is because Denis amazing teaching abilities.\n\n The workouts doesn\'t consist of just mindlessly repeating the exercises until you are able to do it, which usually takes more time to master the exercise, but of useful progressions which will reduce the risk of injury (due to too high ego) and leads you step by step to the advance skills you want to master. We exercise in groups but depending on your level Denis will take care your needs and assign you the exercises that will appropriately challenge you at your current level.\n\n Another positive aspect is the amazing Community at Barliner Calisthenics which makes the workouts fun and challenging. We keep pushing ourself to the limits which is very difficult if you\'re working out alone. Here at Barliner Calisthenics it is not only "no pain no gain" but because of Denis it is also "no brain no gain". 10/10!',
          joiningYear: '2019'
        },
        {
          id: 6,
          name: 'Mara',
          message: 'Right from the beginning of the first training I felt so welcome and like I was part of the group. It’s great how a group of so many different people can be such a strong team and still be so open to accept new people right away. I absolutely love the spirit of our crew. Everybody looks out for each other, supports their teammates and motivates and corrects them. Which is why the atmosphere at every training is very good and also intense. That is one of the reasons why I can push myself harder than I could before. \n\nAnother awesome thing is that the training is different every time and Denis always looks for specific exercises that are the right ones for you. I just started but I can already feel improvements (not only physical but also in my mindset) and have to say, that this is an important part of my time here in Berlin. Cheers!',
          joiningYear: '2017'
        },
        {
          id: 10,
          name: 'Kirill',
          message: 'Train till you faint. Nothing better than Barliner calithenics to 🔥 off that Corona fat and impress the Ex who dumped you during the lockdown.',
          joiningYear: '2020'
        },
        {
          id: 1,
          name: 'Alex',
          message: 'Finding Barliner has been a massive part of my Berlin experience. Denis brings a knowledgeable, thoughtful, and non-judgemental approach to his training. The classes are great mix of basics and skills training, focusing on long-term development, rather than fast results. In addition to being an awesome trainer, Denis has also created a fun and supportive community for calisthenics enthusiasts of all levels.\n\n If you want to get fit, learn skills (the right way), and meet an awesome bunch of people, then this is place for you.',
          joiningYear: '2015'
        },
        {
          id: 2,
          name: 'Carsten',
          message: 'Denis never gets tired of showing you, how to do the movements really correctly, down to the smallest muscles in your body. He always brings new exercises to his classes, and scaling options for every level of training. He’s a great support, and I got so much better over time. It makes so much fun to train with him. Thumbs Up for the best coach I can imagine!',
          joiningYear: '2019'
        },
        {
          id: 3,
          name: 'Liviu',
          message: 'Keep up the sword of good cheer, and thank you again for your efforts and enthusiasm!',
          joiningYear: '2020'
        },
        {
          id: 4,
          name: 'Engin',
          message: 'Ich genieße jedes Training und ganz gleich wie gut oder schlecht ich mich fühle vor dem Training, danach geht es mir immer besser. Es tut mir offensichtlich körperlich gut aber besonders mental ist es eine super Therapie.',
          joiningYear: '2020'
        },
        {
          id: 7,
          name: 'Oksana',
          message: 'Joined the comunity 5 years ago. We went through many things. I like to see the progress of the community. Thanks to Barliner I got motivated to do a Trainer licence and it brought me to my jobs as a trainer today. Specially, I like this feeling in the training with Barliner. People are very cool, heartwarming atmosphere, a lot of nonstandard exercises!',
          joiningYear: '2016'
        },
        {
          id: 8,
          name: 'Simon',
          message: 'If you want to: be trained by one of the nicest guys you will ever meet; train alongside a community of friendly, focused and hard-working individuals from around the world; improve your strength, flexibility and muscular definition in a more efficient way than you will ever do in a gym; and have a s**t-tonne of fun and laughs while doing it, then training with Denis at Barliner is the place for you. Well, at least, this has been my experience so far!\n\n I am so so happy I joined the Barliner clan and discovered calisthenics. Every week I am making progress and every week I am buzzing to train. Denis always keeps the sessions fresh and exciting, pushes you to your absolutel limit and manages to do so while making both you and he smile.\n\n Thanks to Denis and everyone at Barliner for the love and support!',
          joiningYear: '2016'
        },
        {
          id: 9,
          name: 'Robyn',
          message: 'Gymnastics for adults!\n\n After 11 months of looking for a sport that pushes my mind and body I finally went to one of the Barliner Calisthenics classes and fell in love! I had done crossfit for a few years but it really lacked enough focus in the gymnastic element of the training.\n\n I love this sport, both as a mental challenge and as exercise to tone my body and push me further every time. Additionally, it is great finding a sport with such a friendly group of people like the Barliner Calisthenics community.\n\n And the best thing...Den is an amazing instructor. He pushes you but is aware of injuries. I have been to several calisthenics classes in Berlin but now I only go to his classes as he is THE BEST!!!',
          joiningYear: '2019'
        }
      ],
      swiperOptionQuotes: {
        loop: true,
        grabCursor: true,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiperTrainers () {
      return this.$refs.trainersSwiper.$swiper
    },
    swiperQuotes () {
      return this.$refs.quotesSwiper.$swiper
    }
  },
  methods: {
    prevQuote () {
      this.swiperQuotes.slidePrev()
    },
    nextQuote () {
      this.swiperQuotes.slideNext()
    }
  }
}
</script>

<style scoped lang="scss">
  .home {
    .header-bg {
      @extend .img-background;
      height: calc(0.67 * 100vw);
      background-image: url('../assets/imgs/landing_header_bg_2x.jpg');
      background-size: contain;

      @include breakpoint(md) {
        background-size: cover;
        height: calc(0.35 * 100vw);
        background-image: url('../assets/imgs/landing_header_bg_3x.jpg');
        background-position: center -1rem;
      }
    }

    .container-header {
      display: flex;
      position: relative;
      margin-top: -125px;
      padding-top: 4rem;
      text-align: left;

      .div-header-title {
        display: inline-block;
        padding-left: 1rem;

        .logo-header {
          width: 150px;
          height: 24px;
          margin-bottom: 2.5rem;

          @include breakpoint(md) {
            height: 40px;
          }
        }

        .header-title {
          max-width: 340px;
          line-height: 0.85;
        }
      }

      .div-header-cards {
        position: relative;
        width: 100%;

        .div-cards {
          position: absolute;
          top: -8rem;

          .card-header {
            padding-top: 2.2rem;
            padding-bottom: 2.2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            background-color: $secondary;
            text-align: center;

            .h3-bw-custom {
              font-size: 1.3rem;
              font-weight: 500;
              letter-spacing: 1.5px;
            }
          }
        }
      }
    }
    .container-header-mobile {
      .div-header-title-mobile {
        padding: 2.5rem 0;
        text-align: center;
      }

      .div-header-cards-mobile {
        width: 100%;

        .div-cards {
          .card-header-mobile {
            margin-bottom: 1.25rem;
            background-color: $secondary;
            text-align: left;

            .card-body {
              padding-top: 1.7rem;
              padding-bottom: 1rem;
              padding-left: 1rem;
              padding-right: 1rem;
            }

            .h3-bw-custom {
              font-size: 1.3rem;
              font-weight: normal;
              letter-spacing: 2px;
            }

            .btn-grid-mobile {
              display: grid;
              place-content: end;
            }
          }
        }
      }
    }

    .section-promise, .section-calisthenics {
      display: grid;

      @include breakpoint(lg) {
        column-gap: 6rem;
        grid-template-columns: 1fr 1fr;
      }

      .img-landing-group-a {
        @extend .img-background;
        height: 100%;
        min-height: 288px;
        background-image: url('../assets/imgs/landing_group_a_2x.jpg');
        background-size: cover;
        background-position: center;

        @include breakpoint(lg) {
          min-height: 492px;
          background-image: url('../assets/imgs/landing_group_a_3x.jpg');
        }
      }

      .img-landing-group-b {
        @extend .img-background;
        height: 100%;
        min-height: 288px;
        background-image: url('../assets/imgs/landing_group_b_2x.jpg');
        background-size: cover;
        background-position: center;

        @include breakpoint(lg) {
          min-height: 492px;
          background-image: url('../assets/imgs/landing_group_b_3x.jpg');
        }
      }

      .promise-text, .calisthenics-text {
        grid-row: 1;
        border-top: solid 1px #eff0f8;
        padding-top: 2.5rem;
        padding-bottom: 1.5rem;

        h2 {
          margin-left: 2rem;
          margin-right: 2rem;
        }

        @include breakpoint(lg) {
          grid-row: auto;
          border-top: 0;
          align-self: center;
          padding-right: 6rem;
          padding-bottom: 0;

          h2 {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    .card-trainer {
      height: 100px;
      margin-right: 10px;

      @include breakpoint(md) {
        height: 480px;
        margin-right: .75rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position-y: top;
        object-fit: cover;
        object-position: top;
      }
    }

    .swiper-button-prev, .swiper-button-next {
      color: $primary;
      &::after {
        font-size: 1.5rem;
        font-weight: 900;
      }
    }

    .img-classes-1 {
      width: 100%;
    }

    .grid-inclusion {
      position: relative;
      display: grid;
      margin-left: -15px;
      margin-right: -15px;
      padding-bottom: 8.5rem;

      @include breakpoint(lg) {
        grid-template-columns: 1fr 2.55fr;
        margin-left: inherit;
        margin-right: inherit;
        padding-bottom: 5rem;
      }

      &__card {
        display: grid;
        position: absolute;
        bottom: 1.25rem;
        left: 15px;
        right: 15px;
        padding: 2.5rem 1rem;
        background-color: $accent;
        z-index: 1;

        @include breakpoint(md) {
          bottom: inherit;
          left: 0;
          right: inherit;
          padding: 5rem;
          align-self: center;
          width: 500px;
          text-align: left;
        }

        &__content {
          margin: 1.25rem 0;

          @include breakpoint(md) {
            margin: 1.5rem 0;
          }
        }

        &__button {
          margin-top: 1.25rem;
        }
      }

      &__img {
         @extend .img-background;
         background-image: url('../assets/imgs/landing_commitment_2x.png');
         background-size: 100%;
         height: 735px;

         @include breakpoint(lg) {
           background-image: url('../assets/imgs/landing_commitment_3x.png');
           background-position-x: right;
           background-size: cover;
           height: 850px;
           grid-column: 2;
         }
      }
    }

    .trainers-container {
      .trainers-card {
        display: grid;

        &__content {
          margin: 1.25rem 0;

          @include breakpoint(md) {
            margin: 1.5rem 15rem;
          }
        }
      }

      .trainers-swiper-container {
        padding: 1.25rem 0;

        @include breakpoint(md) {
          padding: 2.5rem 0;
        }
      }

      .trainers-actions {
        // display: grid;
        padding: 1.25rem 0;
        row-gap: 1.25rem;

        &__button1 {
            padding: 1rem 2rem;
          }

        // @include breakpoint(md) {
        //   padding: 2.5rem 0;
        //   grid-template-columns: 1fr 1fr 1fr 1fr;
        //   column-gap: 2.5rem;

        //   &__button1 {
        //     grid-column: 2;
        //   }

        //   &__button2 {
        //     grid-column: 3;
        //   }
        // }
      }
    }

    .bg-quote {
      @extend .img-background;

      white-space: pre-line;
      background-size: 15%;
      background-position: center top;
      background-image: url('../assets/imgs/bg-quote.svg');
    }

    .section-divider {
      border-bottom: solid 1px #eff0f8;

      @include breakpoint(md) {
        display: none;
      }
    }
  }
</style>
