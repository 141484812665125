<template>
  <b-container fluid id="app" class="d-flex flex-column min-height-100 p-0">
    <Header></Header>
    <b-container fluid class="main justify-content-center p-0">
      <router-view/>
    </b-container>
    <Footer></Footer>
  </b-container>
</template>

<script>
export default {
  name: 'App',
  components: {
    Header: () => import('@/components/Header'),
    Footer: () => import('@/components/Footer')
  }
}
</script>

<style lang="scss">
  .min-height-100 {
    min-height: 100vh;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    @include breakpoint(md) {
      margin-top: -90px;
    }
  }
</style>
