import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import i18n from './i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import LoadScript from 'vue-plugin-load-script'
import Vuelidate from 'vuelidate'

// styles
import './stylesheets/main.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'swiper/swiper-bundle.css'

// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay])

library.add(faFacebook, faInstagram, faTwitter, faYoutube)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(LoadScript)
Vue.use(Vuelidate)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
